* {
  margin: 0;
  padding: 0;
}
/* *::-webkit-scrollbar {
  display: none;
} */

html,
body,
#root .App {
  /* height: 100%;
  width: 100%;
  overflow: hidden; */
}

html,
body,
#root {
  height: 100%;
}

html {
  /* outline: solid 5px blue; */
  -webkit-text-size-adjust: 100%;
}

body {
  /* font-size: 14px; */
  /* font-family: sans-serif; */
  /* background: #f5f5f5; */
  /* letter-spacing: 0.05em; */
  /* color: #7F7F7F; */
  /* background: #f5f5f5 url(/bg.jpg); */
  background-size: cover;
  background: #202020;
  /* transform: scale(0.75); */
  /* outline: solid 5px red; */
}

body.noscroll {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body.transparent {
  background: none;
}

/* for iframe problems with react-scripts v4 */
body > iframe {
  display: none;
}

img {
  /* touch-action: none; */
  user-select: none;
  vertical-align: middle;
  -webkit-touch-callout: none;
}

.movable {
  transition: all 160ms ease-out;
}
.movable:active {
  transition: none;
  /* z-index: 100; */
}

.user-select-none {
  user-select: none;
}

/*
h1, h2, h3, h4, h5 {
  line-height: 1.2;
}

p {
  line-height: 1.8;
}

li {
  list-style: none;
}

img {
  vertical-align: middle;
  touch-action: none;
  user-select: none;
}

a {
  text-decoration: none;
} */
